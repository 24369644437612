html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);*/
    /*background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);*/
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);

    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blur {
    color: transparent;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.nontextdoc {
    text-decoration: none;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.small-container {
    width: 100%;
    max-width: 400px !important;
    padding: 15px;
    margin: auto;
}

.copyright {
    text-align: center;
    color: #a4a4a4;
}

.id {
    width:1%;
    white-space:nowrap;
}
