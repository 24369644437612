.page-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.page-item {
    position: relative;
    border-radius: 5px;
    max-width: 320px;
    border: 2px solid gray;
    margin: 15px;
}

.page-item-control {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.52);
    border-top-right-radius: 2px;
}
.page-item-control-button {
    margin-top: 5px;
}

.page-item-status {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    border-top: 50px solid gray;
    border-right: 50px solid transparent;
}

.page-item-img {
    max-width: 100%;
    border-radius: 2px 2px 0 0;
}

.page-item-text {
    margin: 10px;
    word-break: break-word;
    min-height: 80px;
}

.page-item-icon {
    width: 15px !important;
}

.col-padding-small {
    padding-left: 0px;
    padding-right: 10px;
}

.log-control-row {
    padding-left: 15px;
}